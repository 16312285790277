export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    access_nivel: [1, 2, 6],
  },
  {
    title: 'Cadastros',
    icon: 'FileTextIcon',
    access_nivel: [6, 2],
    children: [
      {
        title: 'CBOs',
        route: 'list-cbos',
        access_nivel: [6],
      },
      {
        title: 'Conselhos',
        route: 'list-advice',
        access_nivel: [6],
      },
      {
        title: 'Empresas',
        route: 'list-companies',
        access_nivel: [6, 2],
      },
      {
        title: 'Especialidades',
        route: 'list-specialties',
        access_nivel: [6],
      },
      {
        title: 'Fluxogramas',
        route: 'list-flowcharts',
        access_nivel: [6, 2],
      },
      {
        title: 'Motivos de Cancelamento',
        route: 'list-cancellation-reasons',
        access_nivel: [6, 2],
      },
      {
        title: 'Necessidades Especiais',
        route: 'list-necessidades',
        access_nivel: [6],
      },
      {
        title: 'Origens',
        route: 'list-origins',
        access_nivel: [6],
      },
      {
        title: 'Prioridades',
        route: 'list-priorities',
        access_nivel: [6],
      },
      {
        title: 'Profissionais',
        route: 'list-professionals',
        access_nivel: [6, 2],
      },
      {
        title: 'Protocolos',
        route: 'list-protocols',
        access_nivel: [6, 2],
      },
      {
        title: 'Razões de Entrada',
        route: 'list-razoes',
        access_nivel: [6],
      },
      {
        title: 'Setores',
        route: 'list-sectors',
        access_nivel: [6, 2],
      },
      {
        title: 'Usuários',
        route: 'list-users',
        access_nivel: [6, 2],
      },
    ],
  },
  {
    title: 'Classificação',
    route: 'list-triage',
    icon: 'HeartIcon',
    access_nivel: [1, 2, 6],
  },
  {
    title: 'Finalizados',
    route: 'list-triage-finished',
    icon: 'HeartIcon',
    access_nivel: [1, 2, 6],
  },
  {
    title: 'Cancelados',
    route: 'list-triage-cancel',
    icon: 'ClipboardIcon',
    access_nivel: [1, 2, 6],
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
    access_nivel: [1, 2, 6],
  },
]
